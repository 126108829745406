import { localI18n } from '~src/i18n.ts'

/**
 * Important Note:
 *   This file can be merged with `bootstrap-datepicker.js` in the future!
 *   Requirement is that all datepicker scripts are migrated to vite.
 * @see public/js/src/default/bootstrap-datepicker.js
 */

// generated by script
export const i18nMessages = {
  'de-AT': {
    Heute: 'Heute',
    Prozent: 'Prozent',
    Gestern: 'Gestern',
    'Diese Woche': 'Diese Woche',
    'Letzte Woche': 'Letzte Woche',
    Mo: 'Mo',
    Di: 'Di',
    Mi: 'Mi',
    Do: 'Do',
    Fr: 'Fr',
    Sa: 'Sa',
    So: 'So',
    'Dieses Jahr': 'Dieses Jahr',
    'Letztes Jahr': 'Letztes Jahr',
    Jän: 'Jän',
    Feb: 'Feb',
    Mär: 'Mär',
    Apr: 'Apr',
    Mai: 'Mai',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Aug',
    Sep: 'Sep',
    Okt: 'Okt',
    Nov: 'Nov',
    Dez: 'Dez',
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag',
    Son: 'Son',
    Mon: 'Mon',
    Die: 'Die',
    Mit: 'Mit',
    Don: 'Don',
    Fre: 'Fre',
    Sam: 'Sam',
    Jänner: 'Jänner',
    Februar: 'Februar',
    März: 'März',
    April: 'April',
    Juni: 'Juni',
    Juli: 'Juli',
    August: 'August',
    September: 'September',
    Oktober: 'Oktober',
    November: 'November',
    Dezember: 'Dezember',
    Jan: 'Jan',
    Febr: 'Febr',
    Sept: 'Sept',
    'Diesen Monat': 'Diesen Monat',
    'Letzten Monat': 'Letzten Monat',
    Zurücksetzen: 'Zurücksetzen',
  },
  'de-DE': {
    Heute: 'Heute',
    Prozent: 'Prozent',
    Gestern: 'Gestern',
    'Diese Woche': 'Diese Woche',
    'Letzte Woche': 'Letzte Woche',
    Mo: 'Mo',
    Di: 'Di',
    Mi: 'Mi',
    Do: 'Do',
    Fr: 'Fr',
    Sa: 'Sa',
    So: 'So',
    'Dieses Jahr': 'Dieses Jahr',
    'Letztes Jahr': 'Letztes Jahr',
    Jän: 'Jän',
    Feb: 'Feb',
    Mär: 'Mär',
    Apr: 'Apr',
    Mai: 'Mai',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Aug',
    Sep: 'Sep',
    Okt: 'Okt',
    Nov: 'Nov',
    Dez: 'Dez',
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag',
    Son: 'Son',
    Mon: 'Mon',
    Die: 'Die',
    Mit: 'Mit',
    Don: 'Don',
    Fre: 'Fre',
    Sam: 'Sam',
    Jänner: 'Jänner',
    Februar: 'Februar',
    März: 'März',
    April: 'April',
    Juni: 'Juni',
    Juli: 'Juli',
    August: 'August',
    September: 'September',
    Oktober: 'Oktober',
    November: 'November',
    Dezember: 'Dezember',
    Jan: 'Jan',
    Febr: 'Febr',
    Sept: 'Sept',
    'Diesen Monat': 'Diesen Monat',
    'Letzten Monat': 'Letzten Monat',
    Zurücksetzen: 'Zurücksetzen',
  },
  'cs-CZ': {
    Heute: 'Dnes',
    Prozent: 'Procent',
    Gestern: 'Včera',
    'Diese Woche': 'Tento týden',
    'Letzte Woche': 'Minulý týden',
    Mo: 'Po',
    Di: 'Út',
    Mi: 'St',
    Do: 'Čt',
    Fr: 'Pá',
    Sa: 'So',
    So: 'Ne',
    'Dieses Jahr': 'Letos',
    'Letztes Jahr': 'Minulý rok',
    Jän: 'Led',
    Feb: 'Úno',
    Mär: 'Bře',
    Apr: 'Dub',
    Mai: 'Květen',
    Jun: 'Čvn',
    Jul: 'Čvc',
    Aug: 'Srp',
    Sep: 'Zář',
    Okt: 'Říj',
    Nov: 'Lis',
    Dez: 'Pro',
    Sonntag: 'Neděle',
    Montag: 'Pondělí',
    Dienstag: 'Úterý',
    Mittwoch: 'Středa',
    Donnerstag: 'Čtvrtek',
    Freitag: 'Pátek',
    Samstag: 'Sobota',
    Son: 'Ne',
    Mon: 'Po',
    Die: 'Út',
    Mit: 'St',
    Don: 'Čt',
    Fre: 'Pá',
    Sam: 'So',
    Jänner: 'Leden',
    Februar: 'Únor',
    März: 'Březen',
    April: 'Duben',
    Juni: 'Červen',
    Juli: 'Červenec',
    August: 'Srpen',
    September: 'Září',
    Oktober: 'Říjen',
    November: 'Listopad',
    Dezember: 'Prosinec',
    Jan: 'Led',
    Febr: 'Únor',
    Sept: 'Září',
    'Diesen Monat': 'Tento měsíc',
    'Letzten Monat': 'Minulý měsíc',
    Zurücksetzen: 'Zpět',
  },
  'fr-FR': {
    Heute: "Aujourd'hui",
    Prozent: 'Pourcent',
    Gestern: 'Hier',
    'Diese Woche': 'Cette semaine',
    'Letzte Woche': 'La semaine dernière',
    Mo: 'Lu',
    Di: 'Ma',
    Mi: 'Me',
    Do: 'Je',
    Fr: 'Ve',
    Sa: 'Sa',
    So: 'Di',
    'Dieses Jahr': 'Cette année',
    'Letztes Jahr': 'L’année dernière',
    Jän: 'Jan',
    Feb: 'Fév',
    Mär: 'Mars',
    Apr: 'Avr',
    Mai: 'Mai',
    Jun: 'Juin',
    Jul: 'Juil',
    Aug: 'Août',
    Sep: 'Sep',
    Okt: 'Oct',
    Nov: 'Nov',
    Dez: 'Déc',
    Sonntag: 'Dimanche',
    Montag: 'Lundi',
    Dienstag: 'Mardi',
    Mittwoch: 'Mercredi',
    Donnerstag: 'Jeudi',
    Freitag: 'Vendredi',
    Samstag: 'Samedi',
    Son: 'Dim',
    Mon: 'Lun',
    Die: 'Mar',
    Mit: 'Mer',
    Don: 'Jeu',
    Fre: 'Ven',
    Sam: 'Sam',
    Jänner: 'Janvier',
    Februar: 'Février',
    März: 'Mars',
    April: 'Avril',
    Juni: 'Juin',
    Juli: 'Juillet',
    August: 'Août',
    September: 'Septembre',
    Oktober: 'Octobre',
    November: 'Novembre',
    Dezember: 'Décembre',
    Jan: 'Jan',
    Febr: 'Fév',
    Sept: 'Sept',
    'Diesen Monat': 'Ce mois-ci',
    'Letzten Monat': 'Le mois dernier',
    Zurücksetzen: 'Réinitialiser',
  },
  'en-GB': {
    Heute: 'Today',
    Prozent: 'Percent',
    Gestern: 'Yesteray',
    'Diese Woche': 'This week',
    'Letzte Woche': 'Last week',
    Mo: 'Mon',
    Di: 'Tue',
    Mi: 'Wed',
    Do: 'Thu',
    Fr: 'Fri',
    Sa: 'Sat',
    So: 'Sun',
    'Dieses Jahr': 'This year',
    'Letztes Jahr': 'Last year',
    Jän: 'Jan',
    Feb: 'Feb',
    Mär: 'Mar',
    Apr: 'Apr',
    Mai: 'May',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Aug',
    Sep: 'Sep',
    Okt: 'Oct',
    Nov: 'Nov',
    Dez: 'Dec',
    Sonntag: 'Sunday',
    Montag: 'Monday',
    Dienstag: 'Tuesday',
    Mittwoch: 'Wednesday',
    Donnerstag: 'Thursday',
    Freitag: 'Friday',
    Samstag: 'Saturday',
    Son: 'Sun',
    Mon: 'Mon',
    Die: 'Tue',
    Mit: 'Wed',
    Don: 'Thu',
    Fre: 'Fri',
    Sam: 'Sat',
    Jänner: 'January',
    Februar: 'February',
    März: 'March',
    April: 'April',
    Juni: 'June',
    Juli: 'July',
    August: 'August',
    September: 'September',
    Oktober: 'October',
    November: 'November',
    Dezember: 'December',
    Jan: 'Jan',
    Febr: 'Feb',
    Sept: 'Sept',
    'Diesen Monat': 'This month',
    'Letzten Monat': 'Last month',
    Zurücksetzen: 'Restore',
  },
  'es-ES': {
    Heute: 'Hoy',
    Prozent: 'Por ciento',
    Gestern: 'Ayer',
    'Diese Woche': 'Esta semana',
    'Letzte Woche': 'La semana pasada',
    Mo: 'Lun',
    Di: 'Mar',
    Mi: 'Mie',
    Do: 'Jue',
    Fr: 'Vie',
    Sa: 'Sab',
    So: 'Dom',
    'Dieses Jahr': 'Este año',
    'Letztes Jahr': 'El último año',
    Jän: 'Ene',
    Feb: 'Feb',
    Mär: 'Mar',
    Apr: 'Abr',
    Mai: 'May',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Ago',
    Sep: 'Sep',
    Okt: 'Oct',
    Nov: 'Nov',
    Dez: 'Dic',
    Sonntag: 'Domingo',
    Montag: 'Lunes',
    Dienstag: 'Martes',
    Mittwoch: 'Miércoles',
    Donnerstag: 'Jueves',
    Freitag: 'Viernes',
    Samstag: 'Sábado',
    Son: 'Dom',
    Mon: 'Lun',
    Die: 'Mar',
    Mit: 'Mie',
    Don: 'Jue',
    Fre: 'Vie',
    Sam: 'Sab',
    Jänner: 'Enero',
    Februar: 'Febrero',
    März: 'Marzo',
    April: 'Abril',
    Juni: 'Junio',
    Juli: 'Julio',
    August: 'Agosto',
    September: 'Septiembre',
    Oktober: 'Octubre',
    November: 'Noviembre',
    Dezember: 'Diciembre',
    Jan: 'Ene',
    Febr: 'Feb',
    Sept: 'Sep',
    'Diesen Monat': 'Este mes',
    'Letzten Monat': 'El mes pasado',
    Zurücksetzen: 'Restaurar',
  },
  'pl-PL': {
    Heute: 'Dzisiaj',
    Prozent: 'Procent',
    Gestern: 'Wczoraj',
    'Diese Woche': 'Ten tydzień',
    'Letzte Woche': 'Zeszły tydzień',
    Mo: 'Pon',
    Di: 'Wt',
    Mi: 'Śr',
    Do: 'Czw',
    Fr: 'Pt',
    Sa: 'Sob',
    So: 'Nd',
    'Dieses Jahr': 'Ten rok',
    'Letztes Jahr': 'Zeszły rok',
    Jän: 'Sty',
    Feb: 'Lut',
    Mär: 'Mar',
    Apr: 'Kwie',
    Mai: 'Maj',
    Jun: 'Czer',
    Jul: 'Lip',
    Aug: 'Sier',
    Sep: 'Wrz',
    Okt: 'Paź',
    Nov: 'List',
    Dez: 'Gru',
    Sonntag: 'Niedziela',
    Montag: 'Poniedziałek',
    Dienstag: 'Wtorek',
    Mittwoch: 'Środa',
    Donnerstag: 'Czwartek',
    Freitag: 'Piątek',
    Samstag: 'Sobota',
    Son: 'Niedziela',
    Mon: 'Poniedziałek',
    Die: 'Wtorek',
    Mit: 'Środa',
    Don: 'Czwartek',
    Fre: 'Piątek',
    Sam: 'Sobota',
    Jänner: 'Styczeń',
    Februar: 'Luty',
    März: 'March',
    April: 'April',
    Juni: 'June',
    Juli: 'July',
    August: 'August',
    September: 'Wrzesień',
    Oktober: 'Październik',
    November: 'Listopad',
    Dezember: 'December',
    Jan: 'Sty',
    Febr: 'Lut',
    Sept: 'Wrze',
    'Diesen Monat': 'W tym miesiącu',
    'Letzten Monat': 'W zeszłym miesiącu',
    Zurücksetzen: 'Przywróć',
  },
}
// !generated by script

// Necessary until all scripts are migrated to vite
export const getDateLocales = () => {
  const { t } = localI18n(i18nMessages)

  return {
    locale_days: [
      t('Sonntag'),
      t('Montag'),
      t('Dienstag'),
      t('Mittwoch'),
      t('Donnerstag'),
      t('Freitag'),
      t('Samstag'),
      t('Sonntag'),
    ],
    locale_daysShort: [t('Son'), t('Mon'), t('Die'), t('Mit'), t('Don'), t('Fre'), t('Sam'), t('Son')],
    locale_daysMin: [t('So'), t('Mo'), t('Di'), t('Mi'), t('Do'), t('Fr'), t('Sa'), t('So')],
    locale_months: [
      t('Jänner'),
      t('Februar'),
      t('März'),
      t('April'),
      t('Mai'),
      t('Juni'),
      t('Juli'),
      t('August'),
      t('September'),
      t('Oktober'),
      t('November'),
      t('Dezember'),
    ],
    locale_monthsShort: [
      t('Jän'),
      t('Feb'),
      t('Mär'),
      t('Apr'),
      t('Mai'),
      t('Jun'),
      t('Jul'),
      t('Aug'),
      t('Sep'),
      t('Okt'),
      t('Nov'),
      t('Dez'),
    ],
    locale_today: t('Heute'),
    locale_clear: t('Zurücksetzen'),
    locale_weeks: [
      t('Sonntag'),
      t('Montag'),
      t('Dienstag'),
      t('Mittwoch'),
      t('Donnerstag'),
      t('Freitag'),
      t('Samstag'),
    ],
    locale_weekabbrs: [t('So'), t('Mo'), t('Di'), t('Mi'), t('Do'), t('Fr'), t('Sa')],
    locale_monthabbrs: [
      t('Jan'),
      t('Febr'),
      t('März'),
      t('Apr'),
      t('Mai'),
      t('Juni'),
      t('Juli'),
      t('Aug'),
      t('Sept'),
      t('Okt'),
      t('Nov'),
      t('Dez'),
    ],
    locale_percent: t('Prozent'),
    locale_yesterday: t('Gestern'),
    locale_tweek: t('Diese Woche'),
    locale_lweek: t('Letzte Woche'),
    locale_tmonth: t('Diesen Monat'),
    locale_lmonth: t('Letzten Monat'),
    locale_tyear: t('Dieses Jahr'),
    locale_lyear: t('Letztes Jahr'),
  }
}
